import { create } from '@storybook/theming'

export default create({
  base: 'dark',
  brandTitle: 'Vortex XXX',
  brandUrl: 'https://neon.id',
  brandImage: 'https://assets.neon.id/images/neo-logo-horizontal-white.svg',
  brandTarget: '_blank',

  fontBase: '"flsn", sans-serif',
  fontCode: 'monospace',
})
